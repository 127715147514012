import React from 'react'
import PropTypes from 'prop-types'
import { withTrans } from '../i18n/withTrans'

const WrapperLayout = ({ children, pageContext, t }) => {
  return <>{children}</>
}

WrapperLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default withTrans(WrapperLayout)
