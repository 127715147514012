const actions = {
  F_SET_FORM_FIELD_VALUE: 'F_SET_FORM_FIELD_VALUE',
  F_REMOVE_FORM_FIELD_VALUE: 'F_REMOVE_FORM_FIELD_VALUE',

  setFormFieldValue: (data, id) => {
    return {
      type: actions.F_SET_FORM_FIELD_VALUE,
      data,
      id
    }
  },

  removeFormFieldValue: (data, id) => {
    return {
      type: actions.F_REMOVE_FORM_FIELD_VALUE,
      data,
      id
    }
  }
}

export default actions
