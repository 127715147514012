import { combineReducers } from 'redux'
import { GroupTravelFormReducer } from './group_travel_form/reducers'
import { FormReducer } from './form/reducers'
import { SearchConnectionReducer } from './connection/reducers'

const rootReducers = combineReducers({
  groupTravelForm: GroupTravelFormReducer,
  genericForm: FormReducer,
  connectionSearch: SearchConnectionReducer
})

export default rootReducers
