exports.components = {
  "component---src-pages-de-nachrichten-[slug]-js": () => import("./../../../src/pages/de/nachrichten/[slug].js" /* webpackChunkName: "component---src-pages-de-nachrichten-[slug]-js" */),
  "component---src-pages-en-news-[slug]-js": () => import("./../../../src/pages/en/news/[slug].js" /* webpackChunkName: "component---src-pages-en-news-[slug]-js" */),
  "component---src-pages-news-preview-js": () => import("./../../../src/pages/news-preview.js" /* webpackChunkName: "component---src-pages-news-preview-js" */),
  "component---src-templates-not-found-js": () => import("./../../../src/templates/notFound.js" /* webpackChunkName: "component---src-templates-not-found-js" */),
  "component---src-templates-order-js": () => import("./../../../src/templates/order.js" /* webpackChunkName: "component---src-templates-order-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

