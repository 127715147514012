const actions = {
  C_SET_FILTERED_OPTIONS: 'C_SET_FILTERED_OPTIONS',

  setCountOfFilteredOptions: data => {
    return {
      type: actions.C_SET_FILTERED_OPTIONS,
      data
    }
  }
}

export default actions
