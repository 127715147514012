import actions from './actions'

// const { F_SET_FORM_FIELD_VALUE, F_REMOVE_FORM_FIELD_VALUE } = actions
const { F_SET_FORM_FIELD_VALUE } = actions

const initialState = {
  allForms: []
}

const FormReducer = (state = initialState, action) => {
  const { type, data, id } = action
  const fieldName = data?.name
  const fieldValue = data?.value
  switch (type) {
    case F_SET_FORM_FIELD_VALUE:
      let updatedForms = state?.allForms
      if (updatedForms && updatedForms.hasOwnProperty(id)) {
        let formFields = updatedForms[id]
        let match = false
        formFields.forEach(field => {
          if (field.hasOwnProperty(fieldName)) {
            field[fieldName] = fieldValue
            match = true
          }
        })

        if (!match) {
          let field = {}
          field[fieldName] = fieldValue
          formFields.push(field)
        }

        updatedForms[id] = formFields
      } else {
        let field = {}
        field[fieldName] = fieldValue
        if (
          !updatedForms[id] ||
          (updatedForms[id] && !Array.isArray(updatedForms[id]))
        ) {
          updatedForms[id] = []
        }
        updatedForms[id].push(field)
        // updatedForms[id] = [
        //   ...updatedForms[id],
        //   {
        //     ...field
        //   }
        // ]
      }

      return {
        ...state,
        allForms: updatedForms
      }
    // case F_REMOVE_FORM_FIELD_VALUE:
    //   // remove form field value TBD
    //   return {
    //     ...state
    //   }
    default:
      return state
  }
}

export { FormReducer }
