import { navigate } from 'gatsby'

export const getHeaderHeight = () => {
  return window.innerWidth > 767 ? 100 : 62
}

export const getScrollOffsetTopForElementId = elementId => {
  const element = document.getElementById(elementId)
  if (element) {
    let newScrollOffsetTop = Math.max(0, element.offsetTop - getHeaderHeight())
    if (newScrollOffsetTop < 200) {
      newScrollOffsetTop = 0
    }
    return newScrollOffsetTop
  }
  return 0
}

export const linkNavigate = to => {
  const internal =
    (to.indexOf('www.') === -1 && to.indexOf('://') === -1) ||
    to.indexOf(process.env.GATSBY_SITE_BASE_URL) !== -1
  if (!internal) {
    window.location.href = to
  } else {
    const toPath =
      to.indexOf('#') === 0 || to.indexOf('?') === 0
        ? window.location.pathname + to
        : to
    const toURL = new URL(
      to.indexOf('://') === -1
        ? process.env.GATSBY_SITE_BASE_URL + toPath
        : toPath
    )
    if (window.location.pathname === toURL.pathname) {
      const elementId = toURL.hash ? toURL.hash.replace('#', '') : undefined
      if (elementId) {
        window.scrollTo({ top: getScrollOffsetTopForElementId(elementId) })
        window.history.replaceState(null, null, '#' + elementId)
      } else {
        window.history.replaceState(null, null, null)
        window.location.reload()
        window.scrollTo({ top: 0 })
      }
    } else {
      navigate(to)
    }
  }
}

export const sanitizeExternalUrl = url => {
  if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
    return `https://${url}`
  }

  return url
}
