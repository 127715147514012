import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const detectOptions = {
  // order and from where user language should be detected
  // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  order: ['path'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain'

  // optional htmlTag with lang attribute, the default is:
  // htmlTag: document.documentElement,
  // htmlTag: typeof document !== 'undefined' ? document.documentElement : null,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  // cookieOptions: { path: '/', sameSite: 'strict' }
}

i18next.use(LanguageDetector).init({
  fallbackLng: 'de',
  resources: {
    de: {
      translations: require('./locales/de/translations.json')
    },
    en: {
      translations: require('./locales/en/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  supportedLngs: ['de', 'en'],
  whitelist: ['de', 'en'],
  returnObjects: true,
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    wait: true
  },
  detection: detectOptions
})

i18next.languages = ['de', 'en']

export default i18next
