import actions from './actions'

const { C_SET_FILTERED_OPTIONS } = actions

const initialState = {
  countOfFilteredOptions: 0
}

const SearchConnectionReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case C_SET_FILTERED_OPTIONS:
      return {
        ...state,
        countOfFilteredOptions: data
      }
    default:
      return state
  }
}

export { SearchConnectionReducer }
