const actions = {
  GTF_ADD_CONNECTION: 'GTF_ADD_CONNECTION',
  GTF_DELETE_CONNECTION: 'GTF_DELETE_CONNECTION',
  GTF_DELETE_ALL_CONNECTIONS: 'GTF_DELETE_ALL_CONNECTIONS',

  gtfAddConnection: data => {
    return {
      type: actions.GTF_ADD_CONNECTION,
      data
    }
  },

  gtfDeleteConnection: id => {
    return {
      type: actions.GTF_DELETE_CONNECTION,
      id
    }
  },

  gtfDeleteAllConnections: () => {
    return {
      type: actions.GTF_DELETE_ALL_CONNECTIONS
    }
  }
}

export default actions
