/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'rc-collapse/assets/index.css'
import { getScrollOffsetTopForElementId } from './src/helpers/navigate'

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const elementId = location.hash ? location.hash.replace('#', '') : undefined
  if (elementId) {
    const newScrollOffsetTop = getScrollOffsetTopForElementId(elementId)
    window.scrollTo({ top: newScrollOffsetTop })
    return false
  }

  return true
}
