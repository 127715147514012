import actions from './actions'

const {
  GTF_ADD_CONNECTION,
  GTF_DELETE_CONNECTION,
  GTF_DELETE_ALL_CONNECTIONS
} = actions

const initialState = {
  connections: []
}

const GroupTravelFormReducer = (state = initialState, action) => {
  const { type, data, id } = action
  switch (type) {
    case GTF_ADD_CONNECTION:
      const extendedConnections = [
        ...state?.connections,
        {
          ...data
        }
      ]
      return {
        ...state,
        connections: extendedConnections
      }
    case GTF_DELETE_CONNECTION:
      // find in all connections array by ID and remove
      const allConnections = state?.connections
      const updatedConnections = allConnections.filter(
        connection => connection.resultId !== id
      )
      return {
        ...state,
        connections: updatedConnections
      }
    case GTF_DELETE_ALL_CONNECTIONS:
      // remove all previously selected connection from redux store
      return {
        ...state,
        connections: []
      }
    default:
      return state
  }
}

export { GroupTravelFormReducer }
